const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
  window.location.hostname === '[::1]' ||
  window.location.hostname.match(/^127(?:\.\d+){3}$/)
);

export function register(config) {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

    window.addEventListener('load', () => {
      if (isLocalhost) {
        // 🔹 लोकलहोस्ट पर Service Worker चेक करें
        checkValidServiceWorker(swUrl, config);
      } else {
        // 🔹 प्रोडक्शन में Service Worker को डायरेक्ट रजिस्टर करें
        registerValidSW(swUrl, config);
      }
    });
  }
}

function registerValidSW(swUrl, config) {
  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (!installingWorker) return;

        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              console.log('🔄 New content available! Forcing update...');
              
              // 🔹 पुराने Service Worker को तुरंत हटाकर नया Activate करें
              registration.waiting?.postMessage({ type: 'SKIP_WAITING' });

              // 🔄 Auto-Refresh Page
              window.location.reload();

              if (config && config.onUpdate) {
                config.onUpdate(registration);
              }
            } else {
              console.log('✅ Content is cached for offline use.');
              if (config && config.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        };
      };
    })
    .catch((error) => {
      console.error('❌ Service Worker Registration Failed:', error);
    });
}

function checkValidServiceWorker(swUrl, config) {
  fetch(swUrl, { headers: { 'Service-Worker': 'script' } })
    .then((response) => {
      const contentType = response.headers.get('content-type');
      if (!response.ok || (contentType && !contentType.includes('javascript'))) {
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      console.log('⚠️ No internet connection, running in offline mode.');
    });
}

// ✅ पुराना Service Worker Unregister करने के लिए
export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error('❌ Error while unregistering:', error);
      });
  }
}
