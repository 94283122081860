import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Header from "../Components/Header";
import Swal from "sweetalert2";
import CryptoJS from "crypto-js"; // Import CryptoJS for decryption

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY; // Ensure it's the same as backend

// Function to decrypt data received from backend
const decryptData = (encryptedData) => {
  if (!encryptedData) return null;
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
    const decryptedText = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedText ? JSON.parse(decryptedText) : null; // Convert back to JSON
  } catch (error) {
    console.error("Decryption failed:", error);
    return null; // Return null if decryption fails
  }
};

const Redeem = ({ walletUpdate }) => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  const baseUrl = nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;

  const history = useHistory();
  
  const [amount, setAmount] = useState(""); 
  const [userAllData, setUserAllData] = useState(null);
  const [walletBalance, setWalletBalance] = useState(0);
  const [referralEarnings, setReferralEarnings] = useState(0);

  // Fetch user data including wallet balance and referral earnings
  const fetchUserData = async () => {
    try {
      const access_token = localStorage.getItem("token");
      if (!access_token) {
        history.push("/login");
        return;
      }

      const headers = { Authorization: `Bearer ${access_token}` };
      const res = await axios.get(`${baseUrl}me`, { headers });

      console.log("Encrypted User Data:", res.data);

      const decryptedUserData = decryptData(res.data.data); // Decrypt response

      if (decryptedUserData) {
        setUserAllData(decryptedUserData);
        setWalletBalance(decryptedUserData.Wallet_balance || 0);
        setReferralEarnings(decryptedUserData.referral_wallet || 0);
      } else {
        console.error("Failed to decrypt user data");
      }
    } catch (e) {
      console.error("Error fetching user data:", e);
      if (e.response && e.response.status === 401) {
        localStorage.removeItem("token");
        history.push("/login");
      }
    }
  };

  useEffect(() => {
    fetchUserData();
    // eslint-disable-next-line
  }, []);

  // Handle deposit request
  const deposit = async () => {
    if (!amount || amount < 100 || amount > 100000) {
      let msg = "Enter a valid amount!";
      if (!amount) msg = "Please enter an amount.";
      else if (amount < 100) msg = "Amount should be at least ₹100.";
      else if (amount > 100000) msg = "Maximum redeem limit is ₹100000.";

      Swal.fire({
        title: msg,
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }

    try {
      const access_token = localStorage.getItem("token");
      if (!access_token) {
        history.push("/login");
        return;
      }

      const headers = { Authorization: `Bearer ${access_token}` };
      const res = await axios.post(`${baseUrl}referral/to/wallet`, { amount }, { headers });

      if (res.data.msg === "Invalid Amount") {
        Swal.fire({
          title: "Insufficient Referral Balance!",
          text: "You don't have enough amount in referral wallet.",
          icon: "warning",
          confirmButtonText: "OK",
        });
      } else {
        walletUpdate();
        fetchUserData(); // Refresh wallet balance after successful transaction
        Swal.fire({
          title: "Success!",
          text: "Amount successfully redeemed to your main wallet.",
          icon: "success",
          confirmButtonText: "OK",
        });
        setAmount(""); // Reset amount field after success
      }
    } catch (e) {
      Swal.fire({
        title: "Error!",
        text: "Something went wrong. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
      });

      if (e.response && e.response.status === 401) {
        localStorage.removeItem("token");
        history.push("/login");
      }
    }
  };

  return (
    <>
      <Header user={userAllData} />
      <div className="leftContainer" style={{ minHeight: "100vh", height: "100%" }}>
        <div className="mt-5 py-4 px-3">
          <div className="games-section mt-2">
            <div className="games-section-title">Redeem Your Referral Balance</div>
            <div className="games-section-headline mt-2" style={{ fontSize: "0.85em" }}>
              TDS (0%) will be deducted after annual referral earnings of ₹15,000.
            </div>
          </div>

          {/* Wallet Balance and Referral Earnings */}
          <div className="games-section-headline mt-2">
            <b>💰 Wallet Balance: ₹{walletBalance.toFixed(2)}</b>
          </div>
          <div className="games-section-headline mt-2">
            <b>🎁 Referral Earnings: ₹{referralEarnings.toFixed(2)}</b>
          </div>

          <div className="games-section-headline mt-2">
            Enter Amount (Min: ₹100, Max: ₹100000)
          </div>

          <div className="MuiFormControl-root MuiTextField-root mt-4">
            <input
              className="w3-input input"
              type="number"
              style={{ width: "100%" }}
              value={amount}
              placeholder="Enter Amount"
              onChange={(e) => {
                const value = e.target.value;
                if (value === "" || (!isNaN(value) && parseInt(value) >= 0)) {
                  setAmount(value === "" ? "" : parseInt(value)); // Prevent NaN
                }
              }}
            />
            <small className="text-warning">Minimum withdrawal amount ₹100</small>
            <p className="MuiFormHelperText-root">Money will be added to cash.</p>
          </div>

          <button className="refer-button cxy bg-primary mt-5" style={{ width: "29%" }} onClick={deposit}>
            Redeem
          </button>
        </div>
      </div>
    </>
  );
};

export default Redeem;
