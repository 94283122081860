import React from 'react';
import ReactDOM from 'react-dom/client';  // ✅ React 18 के लिए सही इम्पोर्ट
import { BrowserRouter } from 'react-router-dom';
import App2 from './app2.js/App2';
import './app/App.scss';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// ✅ React 18 के लिए createRoot का उपयोग करें
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <App2 />
  </BrowserRouter>
);

// ✅ Service Worker को सही तरीके से Register किया गया है:
if ('serviceWorker' in navigator) {
  serviceWorkerRegistration.register({
    onUpdate: (registration) => {
      // 🔹 नया अपडेट मिलने पर यूज़र को पेज रिफ्रेश करने का अलर्ट दिखाएगा
      if (registration.waiting) {
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });

        // 🔄 नया अपडेट उपलब्ध है, रिफ्रेश करने के लिए अलर्ट दिखाएँ:
        window.location.reload();
      }
    }
  });
}
