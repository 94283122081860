import React from 'react';

const UnderMaintenance = () => {
  const imageUrl = process.env.PUBLIC_URL + "/maintain.webp";

  return (
    <div className="container text-center my-5">
      <div className="row justify-content-center">
        <div className="col-md-8 col-sm-10">
          <img
            src={imageUrl}
            alt="Website Under Maintenance Notice"
            className="img-fluid mb-4"
            onError={(e) => e.target.style.display = 'none'} // Hide image if not found
          />
          <h2 className="mb-3">We're Under Maintenance</h2>
          <p className="mb-4">
            Our site is temporarily down for scheduled maintenance. We apologize for any inconvenience.
          </p>
          <div className="alert alert-warning p-3" role="alert">
            <strong> प्रिय खिलाड़ी,<br /><br /> हम आपको सूचित करना चाहते हैं कि हम LudoKheloYaar वेबसाइट पर कुछ जरूरी अपडेट्स और सुधार पर काम कर रहे हैं। इस समय, गेम के ऑटो-रिजल्ट और ट्रांज़ैक्शन सिस्टम में कुछ समस्याएं आ रही हैं, जिनके कारण साइट कुछ समय के लिए बंद रहेगी। 🙇‍♂️<br /><br /> हम आपको विश्वास दिलाना चाहते हैं कि आपका अमाउंट पूरी तरह से सुरक्षित है और हम इस मुद्दे को जल्द से जल्द ठीक करने के लिए काम कर रहे हैं। हम इस काम को आज रात तक या सुबह 6 बजे से पहले पूरी तरह से हल कर देंगे। 🤞<br /><br /> आपसे अनुरोध है कि थोड़ी देर के लिए धैर्य रखें, हम वापस जल्दी आ रहे हैं और आपका अनुभव और भी बेहतर बनाने का प्रयास कर रहे हैं। धन्यवाद! 🎲 </strong>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnderMaintenance;
