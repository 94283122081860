import React, { useState, useEffect } from "react";
import css from "../css/gamehis.module.css";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { decrypt } from "../utils/cryptoUtils";

const Gamehistory = () => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  const baseUrl = nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;

  const [user, setUser] = useState(null);
  const [game, setGame] = useState([]);
  const [filteredGame, setFilteredGame] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const limit = 50;

  const role = async () => {
    try {
      const access_token = localStorage.getItem("token");
      if (!access_token) return;
      const headers = { Authorization: `Bearer ${access_token}` };
      const res = await axios.get(`${baseUrl}me`, { headers });
      const decryptData = decrypt(res.data.data);
      setUser(decryptData);

      if (decryptData && decryptData._id) {
        Allgames(decryptData._id);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const Allgames = async (id) => {
    try {
      if (!id) return;
      const access_token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${access_token}` };
      const res = await axios.get(`${baseUrl}game/history/user/${id}?page=${pageNumber}&_limit=${limit}`, { headers });
      const decryptData = decrypt(res.data.data);

      const gameHis = decryptData.data.filter(ele =>
        ["completed", "cancelled", "conflict"].includes(ele.Status)
      );

      setGame(gameHis);
      setFilteredGame(gameHis); // For initial load
      setNumberOfPages(decryptData.totalPages || 0);
    } catch (error) {
      console.error("Error fetching game history:", error);
    }
  };

  useEffect(() => {
    role();
  }, [pageNumber]);

  useEffect(() => {
    const lowerSearch = searchTerm.toLowerCase();

    const filtered = game.filter((card) => {
      const opponentName = card.Accepetd_By && user && card.Accepetd_By._id === user._id
        ? card.Created_by?.Name
        : card.Accepetd_By?.Name;

      return (
        (card.Room_code && card.Room_code.toLowerCase().includes(lowerSearch)) ||
        (opponentName && opponentName.toLowerCase().includes(lowerSearch))
      );
    });

    setFilteredGame(filtered);
  }, [searchTerm, game, user]);

  const statusStyles = {
    completed: {
      background: 'linear-gradient(to right, #d4edda, #c3e6cb)',
      color: '#155724',
    },
    cancelled: {
      background: 'linear-gradient(to right, #fff3cd, #ffeeba)',
      color: '#856404',
    },
    conflict: {
      background: 'linear-gradient(to right, #f8d7da, #f5c6cb)',
      color: '#721c24',
    },
  };

  return (
    <div style={{ minHeight: '100vh', backgroundColor: '#f1f3f6', padding: '30px' }}>
      <h2 className="text-center mb-4" style={{ fontWeight: 'bold', color: '#2c3e50' }}>🎮 Game History</h2>

      {/* 🔍 Search Input */}
      <div className="text-center mb-4">
        <input
          type="text"
          className="form-control mx-auto"
          placeholder="🔍 Search by Room Code or User Name..."
          style={{ maxWidth: "400px", borderRadius: "8px", padding: "12px" }}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {filteredGame.length > 0 ? (
        <>
          {filteredGame.map((card, index) => (
            <div key={index}
              className={`w-100 mb-4 p-4 shadow-sm ${css.list_item}`}
              style={{
                borderRadius: '12px',
                ...statusStyles[card.Status],
                transition: 'all 0.3s ease',
              }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', fontWeight: 'bold' }}>
                <span>📅 {new Date(card.createdAt).toLocaleDateString("en-IN")}</span>
                <span>🕒 {new Date(card.createdAt).toLocaleTimeString()}</span>
              </div>

              <hr style={{ margin: '10px 0', borderTop: '1px dashed rgba(0,0,0,0.2)' }} />

              <div className="d-flex flex-column">
                <h5>
                  {card.Status === "completed"
                    ? (card.Winner && user && card.Winner._id === user._id ? '🏆 You Won Against ' : '😞 You Lost To ')
                    : card.Status === 'cancelled' ? '⚠️ Match Cancelled Against ' : '❌ Conflict With '}
                  <b>{
                    card.Accepetd_By && user && card.Accepetd_By._id === user._id
                      ? card.Created_by?.Name
                      : card.Accepetd_By?.Name
                  }</b>
                </h5>

                <p className="mb-1" style={{ color: '#007bff', fontWeight: '500' }}>🔢 Room Code: <b>{card.Room_code}</b></p>
                <p className="mb-1" style={{ color: '#28a745', fontWeight: '500' }}>💰 Entry Amount: ₹<b>{card.Game_Ammount}</b></p>
                <p className="mb-1" style={{ color: '#dc3545', fontWeight: '500' }}>
                  🏦 Closing Balance: ₹<b>{card.Accepetd_By?._id === user?._id ? card.Acceptor_closingbalance : card.Creator_closingbalance}</b>
                </p>
              </div>
            </div>
          ))}

          {/* Pagination Only When No Search */}
          {searchTerm === "" && (
            <div className="pt-3">
              <ReactPaginate
                previousLabel={"⬅️ Prev"}
                nextLabel={"Next ➡️"}
                breakLabel={"..."}
                pageCount={numberOfPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={(data) => setPageNumber(data.selected + 1)}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          )}
        </>
      ) : (
        <div className="text-center mt-5">
          <img src={process.env.REACT_APP_PUBLIC_URL + '/Images/nogamehistory.png'} alt="no data" width={280} className="img-fluid" />
          <h4 className="mt-3 font-weight-bold">📜 No Game History Found</h4>
          <p>🕹️ Play some games to start building your history!</p>
        </div>
      )}
    </div>
  );
};

export default Gamehistory;
