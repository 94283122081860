import React, { useState, useEffect } from "react";
import css from "../css/gamehis.module.css";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { decrypt } from "../utils/cryptoUtils";
import Swal from "sweetalert2";

const Transactionhistory = () => {
    const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
    const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
    const nodeMode = process.env.NODE_ENV;
    const baseUrl = nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;

    const [user, setUser] = useState();
    const [submitBtn, setSubmitBtn] = useState(true);
    const [cardData, setGame] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [numberOfPages, setNumberOfPages] = useState(0);
    const limit = 50;

    const role = async () => {
        const access_token = localStorage.getItem("token");
        const headers = { Authorization: `Bearer ${access_token}` };

        try {
            const res = await axios.get(`${baseUrl}me`, { headers });
            const decryptData = decrypt(res.data.data);
            setUser(decryptData);
            Allgames(decryptData._id);
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    };

    const Allgames = async (id) => {
        const access_token = localStorage.getItem("token");
        const headers = { Authorization: `Bearer ${access_token}` };

        try {
            const res = await axios.get(`${baseUrl}temp/deposite/${id}?page=${pageNumber}&_limit=${limit}`, { headers });
            const decryptData = decrypt(res.data.data);
            const filteredData = decryptData.ress.filter(card => card.Req_type !== "penalty"); // 🔥 Penalty हटाया
            setGame(filteredData);
            setNumberOfPages(decryptData.totalPages);
        } catch (error) {
            console.error("Error fetching transactions:", error);
        }
    };

    useEffect(() => {
        role();
    }, [pageNumber, limit]);

    const handlePageClick = (data) => {
        setPageNumber(data.selected + 1);
    };

    const dateFormat = (e) => {
        const date = new Date(e);
        return date.toLocaleString('default', { day: 'numeric', month: 'short', hour: 'numeric', minute: 'numeric', hour12: true });
    };

    return (
        <div className="leftContainer" style={{ minHeight: '100vh', height: '100%' }}>
            <div className="pt-5">
                <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    pageCount={numberOfPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination justify-content-center"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                />
            </div>

            {cardData.length > 0 ? (
                cardData.map((card, index) => {
                    var id = card._id.toString();
                    var timestamp = id.slice(0, 8);
                    var counterid = parseInt(timestamp, 16);

                    let titleMsg, signIcon;
                    if ((card.Req_type === 'deposit' || card.Req_type === 'bonus') && card.status !== 'FAILED') {
                        titleMsg = <div className="text-success">Cash added</div>;
                        signIcon = card.status === 'Pending' ? <div className="text-danger">(X)</div> : <div className="text-success">(+)</div>;
                    } else if (card.Req_type === "withdraw" && card.status !== 'FAILED') {
                        titleMsg = `Withdraw Using ${card.Withdraw_type}`;
                        signIcon = <div className="text-danger">(-)</div>;
                    } else {
                        titleMsg = 'Transaction Failed';
                        signIcon = <div className="text-danger">(X)</div>;
                    }

                    return (
                        <div key={index} className={`w-100 mt-3 py-3 d-flex align-items-center ${css.list_item}`}>
                            <div className={`${css.center_xy} ${css.list_date} mx-2`}>
                                <div>{dateFormat(card.createdAt).split(',')[0]}</div>
                                <small>{dateFormat(card.createdAt).split(',')[1]}</small>
                            </div>
                            <div className={`${css.list_divider_y}`} />
                            <div className={`mx-3 d-flex ${css.list_body}`}>
                                <div className="d-flex align-items-center">
                                    <img height="32px" width="32px" src={process.env.PUBLIC_URL + '/Images/LandingPage_img/ludo.jpeg'} alt="" style={{ borderRadius: "5px" }} />
                                </div>
                                <div className="d-flex flex-column font-8">
                                    <div>
                                        <b>{titleMsg}</b>
                                        <h6>OrderID: JP-{counterid}</h6>
                                    </div>
                                    <div className={`${css.games_section_headline}`}>
                                        Status: {card.status === 'Pending' ? 'Processing' : card.status}
                                        <br /> {card.txn_msg || ''}
                                    </div>
                                </div>
                            </div>
                            <div className="right-0 d-flex align-items-end pr-3 flex-column">
                                <div className="d-flex float-right font-8">
                                    {signIcon}
                                    <img alt="img" height="21px" width="21px" src={process.env.PUBLIC_URL + '/Images/LandingPage_img/global-rupeeIcon.png'} className="snip-img" />
                                    <span className="pl-1">{card.amount}</span>
                                </div>
                                {card.closing_balance && (
                                    <div className={`${css.games_section_headline}`} style={{ fontSize: "0.6em", whiteSpace: 'nowrap' }}>
                                        Closing Balance: {parseFloat(card.closing_balance).toFixed(1)}
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                })
            ) : (
                <div className="text-center">
                    <img src={process.env.PUBLIC_URL + '/Images/notransactionhistory.png'} alt="no data" width={300} height={300} className="img-fluid " style={{ marginTop: "25%" }} />
                    <div className="mt-2">
                        <h3 className="font-weight-bold">No Transaction History</h3>
                        <p className="text-muted"> You have not made any transactions yet.</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Transactionhistory;
