import axios from "axios";
import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
// import deposits from "../../app/components/transaction/Deposits";
import css from "../css/Addcase.module.css";
import "../css/Loader.css";
import Swal from "sweetalert2";
import { decrypt } from "../utils/cryptoUtils";



const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
const nodeMode = process.env.NODE_ENV;

if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
} else {
    baseUrl = beckendLiveApiUrl;
}

const Addcase = ({ walletUpdate }) => {
    const history = useHistory();
    let method = useRef();
    let checkInterval;
    const [userAllData, setUserAllData] = useState();
    const [submitBtn, setSubmitBtn] = useState(true);

    const [global, setGlobal] = useState(100);
    const [next, setNext] = useState(1);
    const [process, setProcess] = useState(false);
    const [isMobile, setMobile] = useState(false);

    const [isCashFreeActive, setCashFreeActive] = useState(false);

    const [isDecentroActive, setDecentroActive] = useState(false);
    const [isManualUPIQR, setManualUPIQR] = useState(false);
    const [upiId, setupiId] = useState('');

    const [isManualPaymentActive, setManualPaymentActive] = useState(false);
    const [isPhonePeActive, setPhonePeActive] = useState(true);
    const [isRazorPayActive, setRazorPayActive] = useState(false);
    const [isUpiGatewayActive, setUpiGatewayActive] = useState(false);
    const [RazorPayAccountName, setAccountName] = useState("test");

    const [qrCode, setQrCode] = useState();
    const [walletOption, setWalletOption] = useState("airtel");
    const [bankCode, setBankCode] = useState(3003);

    const [account_mail_id, setAccount_mail_id] = useState();
    const [account_name, setAccount_name] = useState();
    const [accountPhone, setAccountPhone] = useState();

    const [scrnshot, setScrnshot] = useState("");
    const [scrnshot1, setScrnshot1] = useState("");
    const [upiUtrNum, setupiUtrNum] = useState("");

    // eslint-disable-next-line
    const handleChange = (e) => {
        setScrnshot1(URL.createObjectURL(e.target.files[0]));
        setScrnshot(e.target.files[0]);
    };

    // 1. Manual Payment mode 
    // console.log(userAllData)
    // UPI Payment URL Generate करना
const upiPaymentUrl = `upi://pay?pa=${upiId}&pn=${encodeURIComponent(account_name)}&am=${global}&tn=${upiUtrNum}&tr=${upiUtrNum}`;

    const ManualPayment = async (e) => {
        e.preventDefault();
        setSubmitBtn(false);

        // ✅ Step 1: Check if user ID is available
        if (!userAllData || !userAllData._id) {
            Swal.fire({
                title: "User Data Missing! (यूजर डेटा गायब है!)",
                text: "Please refresh the page and try again. (कृपया पेज रिफ्रेश करें और पुनः प्रयास करें।)",
                icon: "error",
                confirmButtonText: "OK",
            });
            setSubmitBtn(true);
            return;
        }

        // ✅ Step 2: Validate Transaction ID (Must be 12-digit Numeric)
        const transactionIdPattern = /^\d{12}$/; // 12-digit Numeric Check
        if (!transactionIdPattern.test(upiUtrNum)) {
            Swal.fire({
                title: "Invalid Transaction ID! (अमान्य ट्रांजेक्शन आईडी!)",
                text: "Transaction ID must be exactly 12 digits. (ट्रांजेक्शन आईडी 12 अंकों की होनी चाहिए।)",
                icon: "warning",
                confirmButtonText: "OK",
            });
            setSubmitBtn(true);
            return;
        }

        const access_token = localStorage.getItem("token");
        const headers = { Authorization: `Bearer ${access_token}` };

        const formData = new FormData();
        formData.append("Transaction_Screenshot", scrnshot);
        formData.append("_id", userAllData._id);
        formData.append("amount", global);
        formData.append("TransactionId", upiUtrNum);

        // ✅ Debugging: Log FormData before sending
        formData.forEach((value, key) => {
            console.log(`${key}: ${value}`);
        });

        try {
            const response = await axios.post(baseUrl + `manual/deposit/txn`, formData, { headers });

            if (response.data.status === "PAID") {
                Swal.fire({
                    title: "Payment Successful! (भुगतान सफल हुआ!)",
                    text: response.data.txn_msg,
                    icon: "success",
                    confirmButtonText: "OK",
                });
            } else if (response.data.status === "Pending") {
                Swal.fire({
                    title: "Payment Pending! (भुगतान लंबित है!)",
                    text: response.data.txn_msg,
                    icon: "info",
                    confirmButtonText: "OK",
                });
            } else {
                Swal.fire({
                    title: "Payment Failed! (भुगतान विफल हुआ!)",
                    text: response.data.msg,
                    icon: "error",
                    confirmButtonText: "OK",
                });
            }
        } catch (error) {
            console.error("API Error:", error.response?.data || error.message);
            Swal.fire({
                title: "Payment Error! (भुगतान में त्रुटि!)",
                text: error.response?.data?.message || "Something went wrong! (कुछ गलत हो गया!)",
                icon: "error",
                confirmButtonText: "OK",
            });
        }

        setSubmitBtn(true);
    };


    //Function to load razorpay script for the display of razorpay payment SDK.
    function loadRazorpayScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    //function will get called when clicked on the pay button.
    async function displayRazorpayPaymentSdk(
        channel,
        method,
        upiMethod,
        razorpay
    ) {
        const res = await loadRazorpayScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("Razorpay SDK failed to load. please check are you online?");
            return;
        }
        //setProcess(true);
        const access_token = localStorage.getItem("token");
        const headers = { Authorization: `Bearer ${access_token}` };
        // creating a new order and sending order ID to backend
        const response = await axios.post(
            baseUrl + `user/razorpay_order`,
            {
                amount: global,
                channel: channel,
                payment_method: method,
                provider: walletOption,
                bankCode: bankCode,
                account_name: account_name,
                payment_gatway: razorpay,
            },
            { headers }
        );

        if (!response) {
            alert("Server error. please check are you online?");
            return;
        }

        //console.log(response.data.orderdata);
        // Getting the order details back
        let order_id = response.data.txnID;
        let order_token = response.data.orderdata.id;
        const data = response.data.orderdata;
        const options = {
            // key: RazorPayKey,
            name: RazorPayAccountName,
            description: "Skill Based Game Tournament",
            order_id: data.id,
            prefill: {
                name: account_name,
                email: account_mail_id,
                contact: accountPhone,
            },
            handler: async (response) => {
                //console.log(response)
                try {
                    const paymentId = response.razorpay_payment_id;

                    //const url = baseUrl+`rozarpay/capture/${paymentId}`;
                    //const captureResponse = await axios.post(url, {},{headers})
                    //console.log(captureResponse.data);
                    checkrazorpaydeposit(order_id, order_token, "SUCCESS", paymentId);
                } catch (err) {
                    checkrazorpaydeposit(order_id, order_token, "FAILED");
                    console.log(err);
                }
            },
            theme: {
                color: "#686CFD",
            },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    }

    const checkrazorpaydeposit = (
        order_id,
        order_token,
        order_status,
        paymentId
    ) => {
        const access_token = localStorage.getItem("token");
        const headers = {
            Authorization: `Bearer ${access_token}`,
        };
        axios
            .post(
                baseUrl + `razorpaydesposit/response`,
                { order_id, order_token, order_status, paymentId },
                { headers }
            )
            .then((res) => {
                const icon = res.data.status === "PAID" ? "success" : "danger";
                const title =
                    res.data.status === "PAID"
                        ? "Deposit submited successfully"
                        : "Transaction Failed";
                history.push("/landing");
                setTimeout(() => {
                    Swal.fire({
                        title: title,
                        icon: icon,
                        confirmButtonText: "OK",
                    });
                }, 1000);
            })
            .catch((e) => {
                if (e.response?.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("token");
                    window.location.reload();
                }
            });
    };

    //use for decentrodepositeupi
    const decentroDepositeUpi = (channel, method, upiMethod, decentropay) => {
        //account_name
        //account_mail_id
        //alert(account_name);
        //if(account_name && account_mail_id){
        setProcess(true);
        const access_token = localStorage.getItem("token");
        const headers = { Authorization: `Bearer ${access_token}` };
        axios.post(baseUrl + `user/decentrodepositeupi`,
            {
                amount: global,
                channel: channel,
                payment_method: method,
                provider: walletOption,
                bankCode: bankCode,
                account_name: account_name,
                payment_gatway: decentropay,
            },
            { headers }
        )
            .then((res) => {

                if (res.data.data.status === "SUCCESS") {
                    // eslint-disable-next-line
                    let order_id = res.data.txnID;
                    // eslint-disable-next-line
                    let order_token = res.data.data.decentroTxnId;
                    //setProcess(false);
                    window.open(res.data.data.data.generatedLink);
                    setTimeout(() => {
                        //checkdecentrodeposit(order_id, order_token)
                        setProcess(false);
                    }, 30000);
                } else {
                    setProcess(false);
                    Swal.fire({
                        title: res.data.data.msg,
                        icon: "danger",
                        confirmButtonText: "OK",
                    });
                }
            })
            .catch((e) => {
                alert(e);
            });

    };

    //use for decentrodepositeupi
    const phonePeDepositeUpi = (channel, method, upiMethod, phonepe) => {
        setProcess(true);
        const access_token = localStorage.getItem("token");
        const headers = {
            Authorization: `Bearer ${access_token}`,
        };
        axios.post(baseUrl + `user/phonedepositeapi`,
            {
                // payment_gatway: phonepe,
                _id: userAllData._id,
                MUID: "MUID" + Date.now(),
                transactionId: 'T' + Date.now(),
                Phone: userAllData.Phone,
                amount: global,
            },
            { headers }
        )
            .then((res) => {
                window.location.href = res.data;
            })
            .catch((e) => {
                alert(e);
            });
    };
    // eslint-disable-next-line
    const checkphonepedeposit = (order_id, order_token) => {
        // console.log(order_token);
        const access_token = localStorage.getItem("token");
        const headers = { Authorization: `Bearer ${access_token}` };
        axios.post(baseUrl + `phonepestatus/response`, { order_id, order_token }, { headers })
            .then(
                (res) => {
                    const icon = res.data.status === "PAID" ? "success" : "danger";
                    const title = res.data.status === "PAID" ? "Deposit submited successfully" : "Transaction in failed";
                    if (res.data.status === "Pending") {
                        setTimeout(() => {
                            checkphonepedeposit(order_id, order_token);
                            Swal.fire({
                                title: title,
                                icon: icon,
                                confirmButtonText: "OK",
                            });
                        }, 6000);
                    } else {
                        history.push("/");
                        setTimeout(() => {
                            Swal.fire({
                                title: title,
                                icon: icon,
                                confirmButtonText: "OK",
                            });
                        }, 1000);
                    }
                }
            ).catch(
                (e) => {
                    if (e.response?.status === 401) {
                        localStorage.removeItem("token");
                        localStorage.removeItem("token");
                        window.location.reload();
                    }
                }
            );
    };

const depositUpiGateway = async (channel, method, upiMethod, upigateway) => {
    setNext(3);  // ✅ Payment Start होते ही Page बदल जाए (Instant Show)

    const access_token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${access_token}` };

    console.time("UPI Gateway API Call Time"); // ⏳ Debugging Start

    try {
        const res = await axios.post(baseUrl + `user/depositeupi`, {
            amount: global,
            channel: channel,
            payment_method: method,
            provider: walletOption,
            bankCode: bankCode,
            account_name: account_name,
            account_mail_id: account_mail_id,
            payment_gatway: upigateway,
        }, { headers });

        console.timeEnd("UPI Gateway API Call Time"); // ⏳ Debugging End

        if (res.data.data.status) {
            let order_id = res.data.txnID;
            let order_token = res.data.data.data.order_id;
            let payment_url = res.data.data.data.payment_url;

            console.log("✅ Payment URL Received:", payment_url);

            // 🔹 अब Payment Page `Addcase` पर ही Show होगा
            setQrCode(payment_url);

            // 🔄 Auto Check Payment Status (Delay नहीं होगा, जल्दी Load होगा)
            checkupideposit(order_id, order_token);
        } else {
            Swal.fire({
                title: res.data.data.msg,
                icon: "danger",
                confirmButtonText: "OK",
            });
        }
    } catch (error) {
        console.error("❌ UPI Gateway Error:", error);
        Swal.fire({
            title: "Payment Error!",
            text: "Something went wrong while processing your payment.",
            icon: "error",
            confirmButtonText: "OK",
        });
    }
};





    const checkupideposit = async (order_id, order_token, retryCount = 0) => {
    let access_token = localStorage.getItem("token");
    let headers = { Authorization: `Bearer ${access_token}` };

    try {
        const response = await axios.post(baseUrl + `depositupipay/response`, { order_id, order_token }, { headers });

        if (response.data.status === "PAID") {
            Swal.fire({
                title: "✅ Payment Successful!",
                text: "Your amount has been added to your wallet.",
                icon: "success",
                confirmButtonText: "OK",
            });

            walletUpdate();
            setQrCode(null);  // ✅ Payment Page Hide कर दें
            setNext(1);  // ✅ वापस AddCase Page पर जाएँ
            
            // ✅ बिना Refresh किए Homepage पर Redirect करें
            window.location.href = "https://ludokheloyaar.com/Homepage/Ludo%20Classics%20Manual";

        } else if (response.data.status === "Pending") {
            setTimeout(() => checkupideposit(order_id, order_token, retryCount + 1), 6000);  // 🔄 Retry
        } else {
            Swal.fire({
                title: "❌ Payment Failed!",
                text: "Your transaction was not successful.",
                icon: "error",
                confirmButtonText: "OK",
            });

            // ✅ Payment Fail हो तो भी Message Hide करें
            setQrCode(null);
            setNext(1);
        }

    } catch (error) {
        console.error("Payment Check Error:", error);
    }
};





    const refreshToken = async () => {
        try {
            const refresh_token = localStorage.getItem("refresh_token");
            if (!refresh_token) return null;

            const response = await axios.post(baseUrl + "auth/refresh", { refresh_token });

            if (response.data.success) {
                localStorage.setItem("token", response.data.new_access_token);
                return response.data.new_access_token;
            } else {
                return null;
            }
        } catch (error) {
            return null;
        }
    };

    // ✅ यहाँ पर `showUpiPaymentPopup` को Define करें
    const showUpiPaymentPopup = (upiUrl) => {
        Swal.fire({
            title: "Scan & Pay",
            html: `<iframe src="${upiUrl}" style="width:100%; height:400px; border:none;"></iframe>`,
            showCancelButton: true,
            confirmButtonText: "OK",
        });
    };





    //use for cashfree gatway
    const deposit = (channel, method, upiMethod) => {
        setProcess(true);
        const access_token = localStorage.getItem("token");
        const headers = { Authorization: `Bearer ${access_token}` };
        axios.post(baseUrl + `user/deposite`,
            {
                amount: global,
                channel: channel,
                payment_method: method,
                provider: walletOption,
                bankCode: bankCode,
            },
            { headers }
        )
            .then((res) => {
                if (res.data.data.payment_method === "app") {
                    window.location.href = res.data.data.data.url;
                    checkInterval = setInterval(
                        (ID) => {
                            checkout(ID);
                        },
                        50000,
                        res.data.txnID
                    );
                } else if (res.data.data.payment_method === "netbanking") {
                    window.location.href = res.data.data.data.url;
                    checkInterval = setInterval(
                        (ID) => {
                            checkout(ID);
                        },
                        50000,
                        res.data.txnID
                    );
                } else if (
                    res.data.data.channel === "link" &&
                    res.data.data.payment_method === "upi"
                ) {
                    checkInterval = setInterval(
                        (ID) => {
                            checkout(ID);
                        },
                        50000,
                        res.data.txnID
                    );
                    window.location.href = res.data.data.data.payload[upiMethod];
                } else if (
                    res.data.data.channel === "qrcode" &&
                    res.data.data.payment_method === "upi"
                ) {
                    setQrCode(res.data.data.data.payload.qrcode);
                    setProcess(false);
                    setNext(3);
                    checkInterval = setInterval(
                        (ID) => {
                            checkout(ID);
                        },
                        50000,
                        res.data.txnID
                    );
                }
            })
            .catch((e) => {
                alert(e);
            });
    };

    const checkout = (paymentID) => {
        const access_token = localStorage.getItem("token");
        const headers = { Authorization: `Bearer ${access_token}` };
        axios.post(
            baseUrl + `checkout/deposite/txn`,
            { txnID: paymentID },
            { headers }
        )
            .then((res) => {
                // alert(res.data.txnStatus)
                if (res.data.txnStatus === "PAID") {
                    walletUpdate();
                    clearInterval(checkInterval);
                    Swal.fire({
                        title: res.data.msg,
                        icon: "success",
                        confirmButtonText: "OK",
                    });
                    setProcess(false);
                    setNext(1);
                } else if (res.data.txnStatus === "FAILED") {
                    walletUpdate();
                    clearInterval(checkInterval);
                    Swal.fire({
                        title: res.data.msg,
                        icon: "error",
                        confirmButtonText: "OK",
                    });
                    setProcess(false);
                    setNext(1);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    useEffect(() => {
        

        const fetchUserData = async () => {
            let access_token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${access_token}` };

            try {
                const res = await axios.get(baseUrl + "me", { headers });
                const decryptedData = decrypt(res.data.data);
                setUserAllData(decryptedData);
                setAccount_mail_id(decryptedData.Email);
                setAccount_name(decryptedData.holder_name);
                setAccountPhone(decryptedData.Phone);
            } catch (e) {
                if (e.response?.status === 401) {
                    const newToken = await refreshToken();
                    if (newToken) {
                        try {
                            const res = await axios.get(baseUrl + "me", {
                                headers: { Authorization: `Bearer ${newToken}` }
                            });
                            const decryptedData = decrypt(res.data.data);
                            setUserAllData(decryptedData);
                            setAccount_mail_id(decryptedData.Email);
                            setAccount_name(decryptedData.holder_name);
                            setAccountPhone(decryptedData.Phone);
                        } catch (err) {
                            console.error("User Data Fetch Error After Refresh:", err);
                        }
                    }
                } else {
                    console.error("User Fetch Error:", e);
                }
            }
        };

        const fetchWebsiteSettings = async () => {
            try {
                const res = await axios.get(baseUrl + "website/setting");
                setCashFreeActive(res.data.data.CashFreeDeposit);
                setRazorPayActive(res.data.data.RazarDeposit);
                setDecentroActive(res.data.data.decentroDeposit);
                setPhonePeActive(res.data.data.PhonepeDeposit);
                setupiId(res.data.data.ManualUpiId);
                setAccount_name(res.data.data.AccountName);
                setManualPaymentActive(res.data.data.ManualDeposit);
                setUpiGatewayActive(res.data.data.UpigatwayDeposit);
                setManualUPIQR(res.data.data.QrCode);
            } catch (e) {
                console.error("Website Settings Fetch Error:", e);
                setCashFreeActive(false);
                setRazorPayActive(false);
                setDecentroActive(false);
                setPhonePeActive(false);
                setupiId('');
                setManualPaymentActive(false);
                setManualUPIQR(false);
                setUpiGatewayActive(false);
                setAccount_name("");
            }
        };

        fetchUserData();
        fetchWebsiteSettings();

        if (navigator.userAgent.match(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i)) {
            setMobile(true);
        }
    }, []);
    
useEffect(() => {
    if (qrCode) {
        console.log("✅ Payment Page Loading...");
        setNext(3); // ✅ जैसे ही QR Code आए, तुरंत Payment Page Load हो
    }
}, [qrCode]);

     
     
     

    const upiLink = `upi://pay?pa=${upiId}&pn=${account_name}&am=${global}.00&cu=INR`;

    const copyUPI = () => {
        navigator.clipboard.writeText(upiId);
        Swal.fire({
            title: 'UPI ID Copied!',
            icon: "success",
            confirmButtonText: "OK",
        });
    };


    return (
        <>

            {
                //userAllData && userAllData.verified === 'verified' &&
                <div
                    className="leftContainer bg-white"
                    style={{ minHeight: "100vh", height: "100%", paddingTop: "60px" }}
                >
                    {Boolean(!process) && (
                        <div>
                            {Boolean(next === 1) && (
                                <div className="px-4  py-5">
                                    <div className={`${css.games_section}`}>
                                        <div className="d-flex position-relative align-items-center">
                                            <div className={`${css.games_section_title}`}>
                                                Choose amount to add
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pb-3">
                                        <div className="MultiFormControl_root mt-4 MuiFormControl-fullWidth">
                                            <div className="MuiFormControl_root MuiTextField-root">
                                                <label
                                                    className={`${css.MuiFormLabel_root} MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink`}
                                                    data-shrink="true"
                                                    style={{ fontSize: "0.8rem", fontWeight: "400" }}
                                                >
                                                    Enter Amount
                                                </label>
                                                <div className="MuiInputBase-root MuiInput-root MuiInput_underline jss58 MuiInputBase-formControl MuiInput-formControl MuiInputBase-adornedStart">
                                                    <div className="MuiInputAdornment-root MuiInputAdornment-positionStart d-flex w-100">
                                                        <div className="MuiInputAdornment-root MuiInputAdornment-positionStart d-flex align-items-center">
                                                            <p className="MuiTypography-root MuiTypography-body1 MuiTypography-colorTextSecondary mt-auto">
                                                                ₹
                                                            </p>
                                                        </div>
                                                        <input
                                                            className={`w3-input input ${css.collapseCard_title} border-bottom text-dark`}
                                                            type="tel"
                                                            id="amountInput"
                                                            style={{ width: "100%", fontSize: "2em" }}
                                                            value={`${global}`}
                                                            onChange={(e) => {
                                                                e.target.value > 0
                                                                    ? e.target.value <= 50000
                                                                        ? setGlobal(parseInt(e.target.value))
                                                                        : setGlobal(50000)
                                                                    : e.target.value < 0
                                                                        ? setGlobal(10)
                                                                        : setGlobal(0);
                                                            }}
                                                        ></input>
                                                    </div>
                                                </div>
                                                <p className="MuiFormHelperText-root">
                                                    Min: 10, Max: 50000
                                                </p>
                                                <div className="alert alert-info mt-2" role="alert"
                                                    style={{ fontSize: "1rem", fontWeight: "bold", color: "#007bff" }}>
                                                    ⚠ अब आप ₹2000 से अधिक भी **UPI ID** से पैसे भेज सकते हैं और
                                                    **Transaction ID** दर्ज करके Wallet में स्वतः जोड़ सकते हैं। 🚀
                                                </div>

                                            </div>
                                        </div>
                                        <div className={`${css.games_window}`}>
                                            {/* ₹100 Button */}
                                            <div
                                                className={`${css.gameCard_container}`}
                                                onClick={() => setGlobal((prev) => Math.min(prev + 100, 50000))}
                                            >
                                                <div className={`${css.add_fund_box}`}>
                                                    <div style={{ display: "flex", alignItems: "baseline" }}>
                                                        <div className={`${css.collapseCard_title} mr-1`} style={{ fontSize: "0.9em" }}>
                                                            ₹
                                                        </div>
                                                        <div className={`${css.collapseCard_title}`} style={{ fontSize: "1.5em" }}>
                                                            100
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* ₹250 Button */}
                                            <div
                                                className={`${css.gameCard_container}`}
                                                onClick={() => setGlobal((prev) => Math.min(prev + 250, 50000))}
                                            >
                                                <div className={`${css.add_fund_box}`}>
                                                    <div style={{ display: "flex", alignItems: "baseline" }}>
                                                        <div className={`${css.collapseCard_title} mr-1`} style={{ fontSize: "0.9em" }}>
                                                            ₹
                                                        </div>
                                                        <div className={`${css.collapseCard_title}`} style={{ fontSize: "1.5em" }}>
                                                            250
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* ₹500 Button */}
                                            <div
                                                className={`${css.gameCard_container}`}
                                                onClick={() => setGlobal((prev) => Math.min(prev + 500, 50000))}
                                            >
                                                <div className={`${css.add_fund_box}`}>
                                                    <div style={{ display: "flex", alignItems: "baseline" }}>
                                                        <div className={`${css.collapseCard_title} mr-1`} style={{ fontSize: "0.9em" }}>
                                                            ₹
                                                        </div>
                                                        <div className={`${css.collapseCard_title}`} style={{ fontSize: "1.5em" }}>
                                                            500
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* ₹1000 Button */}
                                            <div
                                                className={`${css.gameCard_container}`}
                                                onClick={() => setGlobal((prev) => Math.min(prev + 1000, 50000))}
                                            >
                                                <div className={`${css.add_fund_box}`}>
                                                    <div style={{ display: "flex", alignItems: "baseline" }}>
                                                        <div className={`${css.collapseCard_title} mr-1`} style={{ fontSize: "0.9em" }}>
                                                            ₹
                                                        </div>
                                                        <div className={`${css.collapseCard_title}`} style={{ fontSize: "1.5em" }}>
                                                            1000
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className={`${css.refer_footer}`}>
                                        <div className="d-grid gap-2 col-12 mx-auto">
                                            <button
                                                type="button"
                                                className={`${css.block} bg-primary rounded text-white font-weight-bold text-uppercase`}
                                                onClick={() => {
                                                    global >= 10
                                                        ? setNext(2)
                                                        : alert("Minimum deposit is 10");
                                                }}
                                            >
                                                Next
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {Boolean(next === 2) && (
                                <div className="px-3 py-2">
                                    <div className="pb-3">
                                        <div className={`${css.games_section}`}>
                                            <div className="d-flex position-relative align-items-center justify-content-between">
                                                <div
                                                    className={`${css.games_section_title}`}
                                                    style={{ fontSize: "1.1em" }}
                                                >
                                                    Amount to be added ₹<span>{global}</span>
                                                </div>
                                                <button
                                                    type="button"
                                                    onClick={() => setNext(1)}
                                                    className="btn btn-primary text-white font-weight-bold text-uppercase px-2 py-1"
                                                >
                                                    Edit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            background: "rgb(241, 241, 241)",
                                            width: "100%",
                                            height: "10px",
                                            position: "absolute",
                                            left: "0",
                                            right: "0",
                                        }}
                                    ></div>
                                    <div className="d-flex flex-column mt-4">



                                        {Boolean(isUpiGatewayActive && global <= 2000) && (
                                            <div className="card-body mt-2 p-2"
                                                style={{
                                                    backgroundColor: "#fff",
                                                    border: "1px solid #F1948A",
                                                    borderRadius: "7px",
                                                }}
                                            >
                                                <div className="card-title text-danger h4">Attention Please</div>
                                                <p className="card-text h6 text-justify mt-2 mb-0">
                                                    QR कोड का स्क्रीन शॉट लेकर आप पेमेंट कर सकते हो और पेमेंट करने के बाद screen पर जब तक OK लिखा हुआ नही आता है तब तक आपको back नही जाना है
                                                    अन्यथा पेमेंट add होने में रुकावट आ सकती है! थोड़े समय मे UPI पेमेंट सिस्टम चालू हो जायेगा धन्यवाद!
                                                </p>
                                            </div>
                                        )}



                                        {global <= 2000 && Boolean(isUpiGatewayActive) && (
                                            <div
                                                onClick={() => {
                                                    method.current = "upipay";
                                                    depositUpiGateway("link", "upi", "upipay", "upigateway");
                                                }}
                                                className="add-fund-box mt-3 btn px-0"
                                                style={{ paddingTop: "0px", }}
                                            >
                                                <div className="d-flex align-items-center" style={{
                                                    backgroundColor: "#E8F8F5",
                                                    border: "1px solid #008000",
                                                    borderRadius: "7px",
                                                }}>
                                                    <div className="d-flex align-items-center" style={{
                                                        height: "60px",
                                                        display: "flex",
                                                        textAlign: "center",
                                                    }}>
                                                        <img width="40px" src="UPI.png" alt="" />
                                                    </div>
                                                    <div className="d-flex justify-content-center flex-column ml-4">
                                                        <div className="jss30 h3"><strong> Paynow</strong></div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}









                                        {Boolean(isRazorPayActive) && (
                                            <div
                                                onClick={() => {
                                                    method.current = "upipay";
                                                    displayRazorpayPaymentSdk(
                                                        "link",
                                                        "upi",
                                                        "upipay",
                                                        "razorpay"
                                                    );
                                                }}
                                                className="add-fund-box mt-3"
                                                style={{ paddingTop: "0px", height: "60px" }}
                                            >
                                                <div
                                                    className="d-flex align-items-center"
                                                    style={{
                                                        backgroundColor: "#fafafa",
                                                        border: "1px solid #e0e0e0",
                                                        borderRadius: "7px",
                                                    }}
                                                >
                                                    <div
                                                        className="d-flex align-items-center"
                                                        style={{
                                                            height: "60px",
                                                            display: "flex",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        <img
                                                            width="40px"
                                                            src="UPI.png"
                                                            alt=""
                                                            style={{
                                                                marginLeft: "7px",
                                                                paddingBottom: "10px",
                                                                paddingLeft: "3px",
                                                                paddingTop: "5px",
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="d-flex justify-content-center flex-column ml-4">
                                                        <div className="jss30">
                                                            <strong>Deposit Here</strong>
                                                        </div>
                                                        <div className="jss31"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}



                                        {Boolean(isPhonePeActive) && (
                                            <div
                                                onClick={() => {
                                                    method.current = "upipay";
                                                    phonePeDepositeUpi(
                                                        "link",
                                                        "upi",
                                                        "upipay",
                                                        "Phonepe"
                                                    );
                                                }}
                                                className="add-fund-box my-3 mx-2"
                                                style={{ paddingTop: "0px", height: "60px" }}
                                            >
                                                <div
                                                    className="d-flex align-items-center"
                                                    style={{
                                                        backgroundColor: "#fafafa",
                                                        border: "1px solid #e0e0e0",
                                                        borderRadius: "7px",
                                                    }}
                                                >
                                                    <div
                                                        className="d-flex align-items-center"
                                                        style={{
                                                            height: "60px",
                                                            display: "flex",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        <img
                                                            width="40px"
                                                            src="UPI.png"
                                                            alt=""
                                                            style={{
                                                                marginLeft: "7px",
                                                                paddingBottom: "10px",
                                                                paddingLeft: "3px",
                                                                paddingTop: "5px",
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="d-flex justify-content-center flex-column ml-4">
                                                        <div className="jss30">
                                                            <strong>Deposit PhonePe</strong>
                                                        </div>
                                                        <div className="jss31"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}






                                        {Boolean(isDecentroActive) && (
                                            <div
                                                onClick={() => {
                                                    method.current = "upipay";
                                                    decentroDepositeUpi(
                                                        "link",
                                                        "upi",
                                                        "upipay",
                                                        "decentropay"
                                                    );
                                                }}
                                                className="add-fund-box mt-3"
                                                style={{ paddingTop: "0px", height: "60px" }}
                                            >
                                                <div
                                                    className="d-flex align-items-center"
                                                    style={{
                                                        backgroundColor: "#fafafa",
                                                        border: "1px solid #e0e0e0",
                                                        borderRadius: "7px",
                                                    }}
                                                >
                                                    <div
                                                        className="d-flex align-items-center"
                                                        style={{
                                                            height: "60px",
                                                            display: "flex",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        <img
                                                            width="40px"
                                                            src="UPI.png"
                                                            alt=""
                                                            style={{
                                                                marginLeft: "7px",
                                                                paddingBottom: "10px",
                                                                paddingLeft: "3px",
                                                                paddingTop: "5px",
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="d-flex justify-content-center flex-column ml-4 mb-5">
                                                        <div className="jss30">
                                                            <strong>Desposit Now</strong>
                                                        </div>
                                                        <div className="jss31"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {global > 2000 && Boolean(isManualPaymentActive) && (
                                            <div className="container w-100 p-3"
                                                style={{
                                                    backgroundColor: "#f8f9fa",
                                                    borderRadius: "12px",
                                                    border: "2px solid #007bff",
                                                    boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
                                                    padding: "20px"
                                                }}>

                                                {/* Heading */}
                                                <h4 className="text-center text-dark font-weight-bold" style={{
                                                    fontSize: "1.4rem",
                                                    borderBottom: "3px solid #007bff",
                                                    paddingBottom: "10px",
                                                    marginBottom: "15px",
                                                    color: "#333"
                                                }}>
                                                    🔹 नीचे दी गई <span className="text-primary">UPI ID</span> पर पैसे भेजें और फिर <span className="text-success">Transaction ID</span> दर्ज करें।
                                                </h4>

                                                {/* 🔹 UPI ID Display Box */}
<div className="d-flex align-items-center justify-content-between p-3 rounded"
    style={{
        backgroundColor: "#e3f2fd",
        border: "2px solid #007bff",
        fontSize: "1.2rem",
        fontWeight: "bold",
        color: "#007bff",
        borderRadius: "8px"
    }}>
    <span id="upiId" className="text-primary">{upiId}</span>
    <button
        className="btn btn-primary font-weight-bold"
        onClick={async () => {
            try {
                await navigator.clipboard.writeText(upiId);
                Swal.fire({
                    title: "✅ UPI ID कॉपी हो गई!",
                    text: "आप इसे अपने UPI ऐप में पेस्ट कर सकते हैं।",
                    icon: "success",
                    confirmButtonText: "OK",
                });
            } catch (err) {
                Swal.fire({
                    title: "❌ क्लिपबोर्ड एक्सेस फेल हुआ!",
                    text: "Manually copy करें।",
                    icon: "error",
                    confirmButtonText: "OK",
                });
            }
        }}
    >
        📋 Copy
    </button>
</div>

{/* 🔹 Pay via UPI Apps Button */}
<a href={upiPaymentUrl} target="_blank" rel="noopener noreferrer">
    <button className="btn btn-success btn-lg font-weight-bold px-4 py-2 mt-3"
        style={{
            backgroundColor: "#28a745",
            border: "none",
            borderRadius: "8px",
            fontSize: "1.2rem",
            padding: "10px 20px",
            width: "100%"
        }}>
        💰 Pay via UPI Apps
    </button>
</a>


                                                {/* Transaction ID Input & Form */}
                                                <form onSubmit={ManualPayment}>
                                                    <div className="mt-4">
                                                        <label htmlFor="transactionId" className="text-dark font-weight-bold" style={{
                                                            fontSize: "1.1rem"
                                                        }}>
                                                            🔽 Enter Transaction ID:
                                                        </label>
                                                        <input
                                                            type="text"
                                                            id="transactionId"
                                                            className="form-control mt-2"
                                                            placeholder="Enter Transaction ID"
                                                            value={upiUtrNum}
                                                            onChange={(e) => setupiUtrNum(e.target.value)}
                                                            required
                                                            style={{
                                                                border: "2px solid #28a745",
                                                                fontSize: "1.2rem",
                                                                fontWeight: "bold",
                                                                color: "#28a745",
                                                                borderRadius: "8px"
                                                            }}
                                                        />
                                                    </div>

                                                    {/* Submit Button */}
                                                    <div className="mt-4 text-center">
                                                        <button type="submit" className="btn btn-success btn-lg font-weight-bold px-4 py-2"
                                                            style={{
                                                                backgroundColor: "#28a745",
                                                                border: "none",
                                                                borderRadius: "8px",
                                                                fontSize: "1.2rem",
                                                                padding: "10px 20px",
                                                                width: "100%"
                                                            }}>
                                                            ✅ Submit
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        )}







                                        {Boolean(isMobile) && Boolean(isCashFreeActive) && (
                                            <div>
                                                <div
                                                    onClick={() => {
                                                        method.current = "gpay";
                                                        deposit("link", "upi", "gpay");
                                                    }}
                                                    className="add-fund-box mt-3"
                                                    style={{ paddingTop: "0px", height: "60px" }}
                                                >
                                                    <div
                                                        className="d-flex align-items-center"
                                                        style={{
                                                            backgroundColor: "#fafafa",
                                                            border: "1px solid #e0e0e0",
                                                            borderRadius: "7px",
                                                        }}
                                                    >
                                                        <div
                                                            className="d-flex align-items-center"
                                                            style={{
                                                                height: "60px",
                                                                display: "flex",
                                                                textAlign: "center",
                                                            }}
                                                        >
                                                            <img
                                                                width="40px"
                                                                src="gpay-logo.png"
                                                                alt=""
                                                                style={{
                                                                    marginLeft: "7px",
                                                                    paddingBottom: "10px",
                                                                    paddingLeft: "3px",
                                                                    paddingTop: "5px",
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="d-flex justify-content-center flex-column ml-4">
                                                            <div className="jss30">
                                                                <strong>G-Pay</strong>
                                                            </div>
                                                            <div className="jss31"></div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div
                                                    onClick={() => {
                                                        method.current = "phonepe";
                                                        deposit("link", "upi", "phonepe");
                                                    }}
                                                    className="add-fund-box mt-3"
                                                    style={{ paddingTop: "0px", height: "60px" }}
                                                >
                                                    <div
                                                        className="d-flex align-items-center"
                                                        style={{
                                                            backgroundColor: "#fafafa",
                                                            border: "1px solid #e0e0e0",
                                                            borderRadius: "7px",
                                                        }}
                                                    >
                                                        <div
                                                            className="d-flex align-items-center"
                                                            style={{
                                                                height: "60px",
                                                                display: "flex",
                                                                textAlign: "center",
                                                            }}
                                                        >
                                                            <img
                                                                width="40px"
                                                                src="/phonepe-logo.png"
                                                                alt=""
                                                                style={{
                                                                    marginLeft: "7px",
                                                                    paddingBottom: "10px",
                                                                    paddingLeft: "3px",
                                                                    paddingTop: "5px",
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="d-flex justify-content-center flex-column ml-4">
                                                            <div className="jss30">
                                                                <strong>PhonePe</strong>
                                                            </div>
                                                            <div className="jss31"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    onClick={() => {
                                                        method.current = "paytm";
                                                        deposit("link", "upi", "paytm");
                                                    }}
                                                    className="add-fund-box mt-3"
                                                    style={{ paddingTop: "0px", height: "60px" }}
                                                >
                                                    <div
                                                        className="d-flex align-items-center"
                                                        style={{
                                                            backgroundColor: "#fafafa",
                                                            border: "1px solid #e0e0e0",
                                                            borderRadius: "7px",
                                                        }}
                                                    >
                                                        <div
                                                            className="d-flex align-items-center"
                                                            style={{
                                                                height: "60px",
                                                                display: "flex",
                                                                textAlign: "center",
                                                            }}
                                                        >
                                                            <img
                                                                width="40px"
                                                                src="/paytm-logo.png"
                                                                alt=""
                                                                style={{
                                                                    marginLeft: "7px",
                                                                    paddingBottom: "10px",
                                                                    paddingLeft: "3px",
                                                                    paddingTop: "5px",
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="d-flex justify-content-center flex-column ml-4">
                                                            <div className="jss30">
                                                                <strong>Paytm UPI</strong>
                                                            </div>
                                                            <div className="jss31"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {Boolean(!isMobile) && Boolean(isCashFreeActive) && (
                                            <div
                                                className="add-fund-box mt-3"
                                                onClick={() => {
                                                    deposit("qrcode", "upi");
                                                }}
                                                style={{ paddingTop: "0px", height: "60px" }}
                                            >
                                                <div
                                                    className="d-flex align-items-center"
                                                    style={{
                                                        backgroundColor: "#fafafa",
                                                        border: "1px solid #e0e0e0",
                                                        borderRadius: "7px",
                                                    }}
                                                >
                                                    <div
                                                        className="d-flex align-items-center"
                                                        style={{ height: "60px" }}
                                                    >
                                                        <img
                                                            width="45px"
                                                            src="/qr-scan.png"
                                                            alt=""
                                                            style={{
                                                                marginLeft: "7px",
                                                                paddingLeft: "3px",
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="d-flex justify-content-center flex-column ml-4">
                                                        <div className="jss30">
                                                            <strong>Scan QR Code</strong>
                                                        </div>
                                                        <div className="jss31"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {Boolean(isCashFreeActive) && (
                                            <div className="games-section-title mt-3">
                                                Other Options
                                            </div>
                                        )}

                                        {Boolean(isCashFreeActive) && (
                                            <div
                                                className="add-fund-box mt-3"
                                                onClick={() => {
                                                    setNext(4);
                                                }}
                                                style={{ paddingTop: "0px", height: "60px" }}
                                            >
                                                <div
                                                    className="d-flex align-items-center"
                                                    style={{
                                                        backgroundColor: "#fafafa",
                                                        border: "1px solid #e0e0e0",
                                                        borderRadius: "7px",
                                                    }}
                                                >
                                                    <div
                                                        className="d-flex align-items-center"
                                                        style={{ height: "60px" }}
                                                    >
                                                        <img
                                                            width="45px"
                                                            src="all-wallets.png"
                                                            alt=""
                                                            style={{
                                                                marginLeft: "7px",
                                                                paddingBottom: "10px",
                                                                paddingLeft: "3px",
                                                                paddingTop: "5px",
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="d-flex justify-content-center flex-column ml-4">
                                                        <div className="jss30">
                                                            <strong>Other Wallets</strong>
                                                        </div>
                                                        <div className="jss31"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {Boolean(isCashFreeActive) && (
                                            <div
                                                className="add-fund-box mt-3"
                                                onClick={() => {
                                                    setNext(5);
                                                }}
                                                style={{ paddingTop: "0px", height: "60px" }}
                                            >
                                                <div
                                                    className="d-flex align-items-center"
                                                    style={{
                                                        backgroundColor: "#fafafa",
                                                        border: "1px solid #e0e0e0",
                                                        borderRadius: "7px",
                                                    }}
                                                >
                                                    <div
                                                        className="d-flex align-items-center"
                                                        style={{ height: "60px" }}
                                                    >
                                                        <img
                                                            width="45px"
                                                            src="/bank1.png"
                                                            alt=""
                                                            style={{
                                                                marginLeft: "7px",
                                                                paddingBottom: "10px",
                                                                paddingLeft: "3px",
                                                                paddingTop: "5px",
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="d-flex justify-content-center flex-column ml-4">
                                                        <div className="jss30">
                                                            <strong>Net Banking</strong>
                                                        </div>
                                                        <div className="jss31"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                 {Boolean(next === 3) && qrCode && (
    <div className="d-flex flex-column justify-content-center align-items-center"
        style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#fff",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 9999
        }}>
        
        {/* ✅ Payment Page को Addcase पर ही Load करें */}
        <iframe 
            src={qrCode} 
            style={{
                width: "100%",
                height: "90vh",
                border: "none",
                borderRadius: "10px",
                boxShadow: "0px 4px 10px rgba(0,0,0,0.1)"
            }} 
        ></iframe>

        {/* 🔄 Payment Auto Check */}
        {qrCode && (
            <div className="mt-3" id="paymentMessage">
                <p className="text-center text-danger font-weight-bold">
                    ⚠ कृपया ऊपर दिए गए **Payment Page** में भुगतान करें। <br />
                    भुगतान पूरा होने के बाद पेज को बंद न करें। <br />
                    सिस्टम अपने आप अपडेट हो जाएगा।
                </p>
            </div>
        )}
    </div>
)}




                    {Boolean(process) && (
                        <div className="loaderReturn" style={{ zIndex: "99" }}>
                            <img
                                src={"https://ludokheloyaar.com/Images/LandingPage_img/loaderhi.gif"}
                                style={{ width: "100%" }}
                                alt="img"
                            />
                        </div>
                    )}
                </div>
            }

        </>
    );
};
export default Addcase;
