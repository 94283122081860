import React, { useEffect, useState } from "react";
import Rightcontainer from "../Components/Rightcontainer";
import axios from "axios";

const Support = () => {
  const backendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const backendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;

  const baseUrl = (nodeMode === "development" ? backendLocalApiUrl : backendLiveApiUrl).replace(/\/$/, "");

  const access_token = localStorage.getItem("token");
  const [user, setUser] = useState(null);
  const [websiteSettings, setWebsiteSettings] = useState({});
  const [whatsappLink, setWhatsappLink] = useState("");

  const whatsappNumber = "9672002542";

  useEffect(() => {
    const getUser = async () => {
      if (!access_token) return;
      try {
        const headers = { Authorization: `Bearer ${access_token}` };
        const res = await axios.get(`${baseUrl}/me`, { headers });
        setUser(res.data);
      } catch (e) {
        if (e.response?.status === 401) {
          localStorage.removeItem("token");
        }
      }
    };

    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/settings/data`);
        setWebsiteSettings(response.data);
      } catch (error) {
        console.error("Error fetching settings:", error);
      }
    };

    fetchData();
    getUser();
  }, [baseUrl, access_token]);

  useEffect(() => {
    if (user) {
      setWhatsappLink(
        `https://wa.me/${whatsappNumber}?text=Hello%20admin,%20I%20need%20help.%20My%20phone%20number%20is%20*${user?.Phone || "Not Available"}*`
      );
    }
  }, [user]);

  return (
    <div>
      <div className="leftContainer" style={{ minHeight: "100vh", height: "100%" }}>
        <div className="cxy flex-column" style={{ paddingTop: "16%" }}>
          <img src="https://i.postimg.cc/k92yVCc9/contact-us.webp" width="280px" alt="Contact Us" />
          <div className="games-section-title mt-4" style={{ fontSize: "1.2em", fontWeight: "700", color: "2c2c2c" }}>
            Contact us at below platforms.
          </div>
          <div className="row">
            <div className="col-4 d-flex justify-content-around w-80">
              <a className="cxy flex-column" href="https://t.me/LKYAAR">
                <img width="50px" src="https://i.postimg.cc/HnLbZ5V4/telegram.png" alt="Telegram" />
                <span className="footer-text-bold">Telegram</span>
              </a>
            </div>
            <div className="col-4 d-flex justify-content-around w-80">
              <a className="cxy flex-column" href="https://www.instagram.com/ludokheloyaar/">
                <img width="50px" src="https://i.postimg.cc/Nj3T99xs/instagram.png" alt="Instagram" />
                <span className="footer-text-bold">Instagram</span>
              </a>
            </div>
            <div className="col-4 d-flex justify-content-around w-80">
              <a className="cxy flex-column" href={whatsappLink} target="_blank" rel="noopener noreferrer">
                <img width="50px" src="https://i.postimg.cc/tCj8v45Z/whatsapp.png" alt="WhatsApp" />
                <span className="footer-text-bold">WhatsApp</span>
              </a>
            </div>
          </div>
          <div className="col-12 my-2 text-center font-weight-bold">
            <a className="cxy flex-column" href={`mailto:${websiteSettings.CompanyEmail || ""}`}>
              <img width="50px" src="https://i.postimg.cc/6QV78H3c/mail.jpg" alt="Email" />
              <span className="footer-text-bold">{websiteSettings.CompanyEmail || "Not Available"}</span>
            </a>
          </div>
          <div className="col-12 my-2 text-center font-weight-bold">
            <a className="cxy flex-column">
              <span className="footer-text-bold">
                <a href={`tel:${websiteSettings.CompanyMobile || ""}`}>{websiteSettings.CompanyMobile || "Not Available"}</a>
              </span>
              <span className="footer-text-bold">{websiteSettings.CompanyName || "Not Available"}</span>
              <span className="footer-text-bold">{websiteSettings.CompanyAddress || "Not Available"}</span>
            </a>
          </div>
        </div>
      </div>
      <div className="rightContainer">
        <Rightcontainer />
      </div>
    </div>
  );
};

export default Support;
