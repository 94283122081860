import React, { useEffect, useState } from "react";
import css from "../css/gamehis.module.css";
import axios from "axios";
import Header from "../Components/Header";
import { decrypt } from "../utils/cryptoUtils";

const Referralhis = () => {
  const backendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const backendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  const PUBLIC_URL = process.env.PUBLIC_URL;

  const baseUrl = (nodeMode === "development" ? backendLocalApiUrl : backendLiveApiUrl).replace(/\/$/, '');

  const [user, setUser] = useState(null);
  const [cardData, setCardData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState(""); 
  const recordsPerPage = 20;

  useEffect(() => {
    const fetchUserData = async () => {
      const accessToken = localStorage.getItem("token");
      if (!accessToken) {
        console.error("No access token found");
        setLoading(false);
        return;
      }
      
      const headers = { Authorization: `Bearer ${accessToken}` };

      try {
        const res = await axios.get(`${baseUrl}/me`, { headers });
        const decryptedData = decrypt(res.data.data);
        setUser(decryptedData);
        if (decryptedData?.referral_code) {
          fetchReferralHistory(decryptedData.referral_code);
        } else {
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        setLoading(false);
      }
    };

    const fetchReferralHistory = async (referralCode) => {
      const accessToken = localStorage.getItem("token");
      if (!accessToken) return;
      
      const headers = { Authorization: `Bearer ${accessToken}` };
      
      try {
        const res = await axios.get(`${baseUrl}/referral/code/winn/${referralCode}`, { headers });
        const sortedData = res.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)); // ✅ Latest First
        setCardData(sortedData || []);
      } catch (error) {
        console.error("Error fetching referral history:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [baseUrl]);

  const dateFormat = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("default", {
      month: "long",
      day: "numeric",
      hour: "numeric",
      hour12: true,
      minute: "numeric",
    });
  };

  const formatClosingBalance = (balance) => Math.floor(balance);

  // ✅ Filter Data Based on Search Input
  const filteredData = cardData.filter((card) =>
    card.earned_from?.Name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // ✅ Pagination Logic
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = filteredData.slice(indexOfFirstRecord, indexOfLastRecord);
  const totalPages = Math.ceil(filteredData.length / recordsPerPage);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Header user={user} />

      <div className="leftContainer" style={{ minHeight: "100vh", height: "100%", paddingTop: "80px" }}>
        
        {/* ✅ Stylish Search Box */}
        <div className="d-flex justify-content-center my-3">
          <input
            type="text"
            placeholder="🔍 Search by Name..."
            className="form-control w-50 shadow-sm border-0 p-3"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{ borderRadius: "25px", fontSize: "16px" }}
          />
        </div>

        {filteredData.length === 0 ? (
          <div className="text-center">
            <img
              src={`${PUBLIC_URL}/Images/notransactionhistory.png`}
              alt="No data"
              width={300}
              height={300}
              className="img-fluid"
              style={{ marginTop: "10%" }}
            />
            <div className="mt-2">
              <h3 className="font-weight-bold">No Referral History</h3>
              <p className="text-muted">No records match your search.</p>
            </div>
          </div>
        ) : (
          <>
            {currentRecords.map((card) => (
              <div className={`w-100 py-3 d-flex align-items-center ${css.list_item}`} key={card._id}>
                <div className={`${css.center_xy} ${css.list_date} mx-2`}>
                  <div>{dateFormat(card.createdAt).split(",")[0]}</div>
                </div>

                <div className={`${css.list_divider_y}`} />

                <div className={`mx-3 d-flex ${css.list_body}`}>
                  <div className="d-flex align-items-center">
                    <picture className="mr-2">
                      <img
                        height="32px"
                        width="32px"
                        src={`${PUBLIC_URL}/Images/LandingPage_img/ludo.jpeg`}
                        alt="Game Icon"
                        style={{ borderRadius: "5px" }}
                      />
                    </picture>
                  </div>

                  <div className="d-flex flex-column font-8">
                    <div>
                      <b style={{ color: "black" }}>Referral earning</b>.
                    </div>
                    <div className={`${css.games_section_headline}`}>
                      <span style={{ color: "blue" }}>
                        Earned by: {card.earned_from?.Name || "Unknown"}
                      </span>
                    </div>

                    {/* ✅ Referral Amount Display */}
                    <div className="mt-1 text-success font-weight-bold">
                      Referral Amount: ₹{card.amount}
                    </div>

                    {/* ✅ Closing Balance with Icon */}
                    <div className="mt-1 text-muted" style={{ fontSize: "0.85em" }}>
                      <img
                        src={`${PUBLIC_URL}/Images/LandingPage_img/global-rupeeIcon.png`}
                        alt="Rupee Icon"
                        width="16"
                        height="16"
                        className="mr-1"
                      />
                      Closing Balance: <span className="text-danger font-weight-bold">₹{formatClosingBalance(card.closing_balance)}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            {/* ✅ Pagination */}
            <div className="pagination d-flex justify-content-center mt-4">
              <button disabled={currentPage === 1} onClick={() => setCurrentPage(currentPage - 1)} className="btn btn-primary mx-2">
                Prev
              </button>
              {[...Array(totalPages)].map((_, index) => (
                <button
                  key={index}
                  className={`btn mx-1 ${currentPage === index + 1 ? "btn-dark" : "btn-outline-secondary"}`}
                  onClick={() => setCurrentPage(index + 1)}
                >
                  {index + 1}
                </button>
              ))}
              <button disabled={currentPage === totalPages} onClick={() => setCurrentPage(currentPage + 1)} className="btn btn-primary mx-2">
                Next
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Referralhis;
