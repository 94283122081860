import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from "sweetalert2";
import "../css/kyc.css";
import '../css/Loader.css';

export default function LudoKingDashboard() {
    const [apiKey, setApiKey] = useState('');
    const [showApiKey, setShowApiKey] = useState(false);
    const [showTestOptions, setShowTestOptions] = useState(false);
    const [selectedEndpoint, setSelectedEndpoint] = useState('');
    const [responseData, setResponseData] = useState(null);
    const [inputNumber, setInputNumber] = useState('');
    const [isAdmin, setIsAdmin] = useState(false);  

    useEffect(() => {
        const fetchUserRole = async () => {
            const access_token = localStorage.getItem('token') || '';
            if (!access_token) return;  // ✅ Directly return, not in condition

            try {
                const response = await axios.get('https://api.ludokheloyaar.com/apiv2/me', {
                    headers: { Authorization: `Bearer ${access_token}` }
                });
                setIsAdmin(response.data.role === "admin"); // ✅ Directly update state
            } catch (error) {
                console.error('Error fetching user role:', error);
            }
        };

        fetchUserRole();
    }, []);  // ✅ `useEffect` is outside of any condition

    useEffect(() => {
        const fetchApiKey = async () => {
            const access_token = localStorage.getItem('token') || '';
            if (!access_token) return;

            try {
                const response = await axios.get('https://api.ludokheloyaar.com/apiv2/apikey', {
                    headers: { Authorization: `Bearer ${access_token}` }
                });
                setApiKey(response.data.apikey || 'No API Key Found');
            } catch (error) {
                console.error('Error fetching API key:', error);
            }
        };
        fetchApiKey();
    }, []);

    const handleTestRequest = async () => {
        if (!selectedEndpoint) {
            Swal.fire({ icon: 'error', title: 'Error', text: 'Please select an endpoint', confirmButtonText: 'Close' });
            return;
        }

        if (inputNumber.length !== 8 && selectedEndpoint !== 'https://api.ludokheloyaar.com/apiv2/ludoking?type=0') {
            Swal.fire({ icon: 'error', title: 'Error', text: 'Please enter an 8-digit number', confirmButtonText: 'Close' });
            return;
        }

        try {
            const url = `${selectedEndpoint}&apikey=${apiKey}&roomcode=${inputNumber}`;
            const response = await axios.get(url);
            setResponseData(response.data);
            Swal.fire({ icon: 'success', title: 'Success', text: 'Request successful!', confirmButtonText: 'Close' });
        } catch (err) {
            const errorText = err.response?.data?.error || err.message || 'Request failed';
            Swal.fire({ icon: 'error', title: 'Request Failed', text: errorText, confirmButtonText: 'Close' });
        }
    };

    return (
        <div className="kycPage mt-5 py-4 px-3">
            <h2>Ludo King API Dashboard</h2>
            {isAdmin ? (
                <>
                    <div style={{ marginBottom: '20px' }}>
                        <label style={{ fontWeight: 'bold' }}>API Key:</label>
                        <div style={{ padding: '10px', background: '#f4f4f4', borderRadius: '5px', wordBreak: 'break-all' }}>
                            {showApiKey ? apiKey : '*************'}
                        </div>
                        <button onClick={() => setShowApiKey(!showApiKey)}>
                            {showApiKey ? 'Hide API Key' : 'Show API Key'}
                        </button>
                    </div>
                    <button onClick={() => setShowTestOptions(true)}>Test API Key</button>
                    {showTestOptions && (
                        <div>
                            <label>Select Endpoint:</label>
                            <select value={selectedEndpoint} onChange={(e) => setSelectedEndpoint(e.target.value)}>
                                <option value="">Select an Endpoint</option>
                                <option value="https://api.ludokheloyaar.com/apiv2/ludoking?type=0">Roomcode</option>
                                <option value="https://api.ludokheloyaar.com/apiv2/ludoking?type=1">Roomtype (classic / popular)</option>
                                <option value="https://api.ludokheloyaar.com/apiv2/ludoking?type=2">Result API</option>
                                <option value="https://api.ludokheloyaar.com/apiv2/ludoking?type=3">Result ADV API</option>
                            </select>
                            <label>Enter 8-digit Number:</label>
                            <input type="text" value={inputNumber} onChange={(e) => setInputNumber(e.target.value)} />
                            <button onClick={handleTestRequest}>Send Test Request</button>
                            {responseData && (
                                <div>
                                    <h5>Response:</h5>
                                    <pre>{JSON.stringify(responseData, null, 2)}</pre>
                                </div>
                            )}
                        </div>
                    )}
                </>
            ) : (
                <div>
                    <h2 style={{ textAlign: "center", color: "red" }}>🚧 Maintenance Mode 🚧</h2>
                    <p style={{ textAlign: "center", fontSize: "18px" }}>
                        This page is currently under maintenance. <br />
                        Please check back later.
                    </p>
                </div>
            )}
        </div>
    );
}
